export default function ControlesSocio (props) {

    return (<>
    <div className="flex flex-row justify-center">
        <button
            className="my-2 p-2 mx-10 rounded-xl bg-green-200 hover:bg-green-800 hover:text-white"
            onClick={e => props.guardar()}
        >
            Guardar Cambios
        </button>
        <button
            className="my-2 p-2 mx-10 rounded-xl bg-red-200 hover:bg-red-800 hover:text-white"
            onClick={e => props.setElegido(0)}
        >
             Descartar Cambios
        </button>
    </div>
    </>)
}