export default function BuscarSocioCob(props) {
    const formato = "px-2 flex flex-col border-r-2 border-gray-600 w-full"
    return (<>
    <div className={formato}>
        {props.cual==='s' ? <label className="text-lg font-semibold text-center mb-5">Estado de cuenta cuota Social</label> : ''}
        {props.cual==='d' && props.socio.actividad.length? <label className="text-lg font-semibold text-center mb-5">Estado de cuenta cuota Deportiva</label> : ''}
        {(props.cual==='d' && props.socio.actividad.length) || props.cual==='s' ?
            <div className="flex flex-row w-full justify-between">
                <label>Última cuota paga</label> 
                <label className="text-right">{props.ultima.descrip}</label>
            </div>
        : 
        ''
        }
    </div>
    </>)
}