import { useState } from 'react'

export default function BuscarSocioCob(props) {
    const [apellido, setApellido] = useState('')
    const [nombre, setNombre] = useState('')
    const [dni, setDni] = useState('')
    
    function documento (e) {
        if (( /^[0-9]+$/.test(e) && e.length<9) || e=== '')
            setDni(e)
    }

    function elegir(a) {
        if (!a.grupo_fam_cab && a.grupo_fam_lis!== '') {
            const t = props.socios.filter (e => e.id === parseInt(a.grupo_fam_lis))
            props.setSocio(t[0])
            elegir(t[0])
        } 
        if (a.grupo_fam_cab) {
            const cuales = a.grupo_fam_lis.split(',')
            const lista = cuales.map(e => {
                return props.socios.filter(f => f.id === parseInt(e))[0]
            })
            props.setFamilia(lista)
            props.setSocio(a)
        }
        if (!a.grupo_fam_cab && a.grupo_fam_lis==='') {
            props.setFamilia([])
            props.setSocio(a)
        }
    }

    return (<>
        <div className="grid grid-cols-3 tex-lg justify-items-center py-1 w-full">
            <div>
                Apellido
                <input 
                    className="w-24 p-1 ml-5"
                    type="text"
                    value={apellido}
                    onChange={e => setApellido(e.target.value)}
                />
            </div>
            <div>
                Nombre
                <input 
                    className="w-24 p-1 ml-5"
                    type="text"
                    value={nombre}
                    onChange={e => setNombre(e.target.value)}
                />
            </div>
            <div>
                Documento
                <input 
                    className="w-24 p-1 ml-5"
                    type="text"
                    value={dni}
                    onChange={e => documento(e.target.value)}
                />
            </div>
        </div>
        <ul className="overflow-y-auto cursor-pointer h-[29rem]">
        {props.socios.map(a => {
            if (a.nombre.toLowerCase().includes((nombre).toLowerCase()) && a.apellido.toLowerCase().includes((apellido).toLowerCase()) && a.documento.includes((dni))) 
                return  (<>
                <li 
                    key={a.id} 
                    className="grid grid-cols-3 px-2 text-sm"
                    onClick={e => elegir(a)}
                 >
                <div>{a.apellido}</div>
                <div>{a.nombre}</div>
                <div>{a.documento}</div>
                 </li>
                 </>)
        })}
        </ul>
    </>)
}