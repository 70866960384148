import BuscarSocioCob from './buscar_socio.js'
import Cobrar from './cobrar.js'
import Totales from './totales.js'
import {useState, useEffect} from 'react'
import dayjs from 'dayjs'

export default function Socios(props) {
    const [total_soc, setTotal_soc] = useState(0)
    const [total_dep, setTotal_dep] = useState(0)
    const [total, setTotal] = useState(0)
    const [vencida_s, setVencida_s] = useState([])
    const [valor_s, setValor_s] = useState([])
    const [vencida_d, setVencida_d] = useState([])
    const [valor_d, setValor_d] = useState([])
    const [familia, setFamilia] = useState([])
    const [efe, setEfe] = useState(0)
    const [tra, setTra] = useState(0)

    useEffect (() => {
        function traer (id, tipo, donde) {
            fetch(props.back + 'admin/toda_cuota/id/' + id + '/tipo/' + tipo, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + props.token,
                    'Content-Type': 'application/json' 
                }
            })
            .then (response => response.json())
            .then (json => {
                var sele=0
                const t = json.map(a => {
                    if (!sele) {
                        sele=1
                        return  {...a, s: true}
                    }
                    else
                        return  {...a, s: false}
                })
                donde(t)
            })
        }
        function categoria (socio = props.socio) {
            if (!socio.id) return
            const hoy = dayjs(new Date())
            const nac = dayjs(socio.nacimiento)
            const ed = hoy.diff(nac, 'year')

            const alta_soc = dayjs(socio.alta_sistema)
            const ant = hoy.diff(alta_soc, 'year')
            var cual = 'Menor'
            if (ed>5 && ed<18) cual='Cadete'
            if (ed>=18 && ed<60 && socio.sexo==='F') cual='Dama'
            if (ed>=18 && ed<60 && socio.sexo==='M') cual='Activo'
            if (ed>=60 && ant <=30) cual='Jubilado'

            const v = props.importes.filter(a => a.cate === cual)           
            return socio.bonifi_s ? {categoria: cual, valor: v[0].valor /100.0 * (100 - socio.bonifi_s)} : {categoria: cual, valor: v[0].valor}
        }
        
        function actividad (socio = props.socio) {
            if (!socio.id) return
            const act = socio.actividad.map(a => { 
                const zzz = props.actividades.map(e => {
                    return e.id === a.actividadId ? e.grupo : ''
                })
                return zzz.filter(t => t!== '')
            })
            const v = act.map(e => {
                const u = props.imp_social.map(a => {
                    return e[0] === a.grupo ? a._avg.valor : 0
                })
                const listo = u.filter(t => t)
                return socio.bonifi_a ? {act: e[0], valor: listo[0]/100.0* (100-socio.bonifi_a)} : {act: e[0], valor: listo[0]}
            }) 
            return v.length ? v : []
        }
        if(props.socio.id) {
            traer(props.socio.ultima_s, 'socio', setVencida_s)
            traer(props.socio.ultima_d, 'actividad', setVencida_d)
            var f = [categoria()]
            if(familia.length) 
                familia.map(a => {
                    const t=categoria(a)
                    f.push(t)
                })
            setValor_s(f)
            f = [actividad()]
            if(familia.length) 
                familia.map(a => {
                    const t = actividad(a)
                    if (t.length)
                        f.push(t)
                })
           setValor_d(f)
        }
    },[props.socio])

    useEffect(() =>{
        if(!props.socio.id) return
        function calcular_s() {
            var tot = 0 
            valor_s.map( a => tot += a.valor)
            setTotal_soc(vencida_s!==undefined ? vencida_s.filter(a => a.s).length * tot : tot)
        }
        calcular_s()
    },[vencida_s])

    useEffect(() => {
        if(!props.socio.id) return
        function calcular_d() {
            var tot=0
            valor_d.map(a => {a.map(z => tot += z.valor)})
            setTotal_dep(vencida_d!==undefined ? vencida_d.filter(a => a.s).length * tot : tot)
        }
        calcular_d()
    }, [vencida_d])

    function cobrar() {
        const pagada_s = vencida_s.filter(a => a.s)
        const pagada_d = vencida_d.filter(a => a.s)
        var data = {
            efectivo : efe,
            transferencia: tra,
            socioId: props.socio.id,
            valor_s: valor_s,
            pagada_s: pagada_s,
            valor_d: valor_d,
            pagada_d: pagada_d
        }
        data = props.socio.saldo_ant ? {...data, saldo_ant: props.socio.saldo_ant} : data
        fetch(props.back + 'admin/recibo_guardar', {
            body : JSON.stringify(data),
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + props.token,
                'Content-Type': 'application/json' 
            }
        })
        .then (response => response.json())
        .then (json => {
                props.setRecargar(props.recargar + 1)
                props.setSocio({...props.socio, id: 0})
        })
    }
    return (<>
    <div className="h-screen flex flex-col">
    {(props.socio.id===undefined || props.socio.id===0) ?
        <div className="max-h-1/2 p-2">
            <BuscarSocioCob back={props.back} token={props.token} setSocio={props.setSocio} socios={props.socios} setRecargar={props.setRecargar} recargar={props.recargar} familia={familia} setFamilia={setFamilia} />
        </div>
    : 
        <div className="h-full">
        <div className="p-2 min-h-[70%] overflow-y-auto">
            <Cobrar back={props.back} token={props.token} setSocio={props.setSocio} setRecargar={props.setRecargar} recargar={props.recargar} socio={props.socio} actividades={props.actividades}
             vencida_s={vencida_s} setVencida_s={setVencida_s} vencida_d={vencida_d} setVencida_d={setVencida_d} valor_s={valor_s} valor_d={valor_d} familia={familia}/>
        </div>
        <div className="border-t-2 border-gray-600">
            <Totales total_soc={total_soc} total_dep={total_dep} total={total} setTotal={setTotal} cobrar={cobrar} efe={efe} tra={tra} setEfe={setEfe} setTra={setTra} socio={props.socio}/>
        </div>
        </div>
    }


    </div>
    </>)
}