export default function Quien (props) {
    return (<>
        <div className="text-gray-200 flex flex-col pr-2">
            <div className="flex flex-row justify-between">
               <label className="font-semibold pr-5">Usuario: </label>
               <label>{props.datos_u.apellido + ' ' + props.datos_u.nombre}</label>  
            </div>
            <div className="flex flex-row justify-between">
               <label className="font-semibold pr-5">Ultimo Ingreso:</label>
               <label>{props.datos_u.ultimo_ing.substr(0, props.datos_u.ultimo_ing.indexOf('T'))}</label>  
            </div>
        </div>
    </>)
}