import {useState, useEffect } from 'react'
import EstadoCuentas from './estado_cuentas.js'
import Vencidas from './vencidas.js'
import VencidasSoc from './vencidas_social.js'

export default function BuscarSocioCob(props) {
    const [ultima_s, setUltima_s] = useState([])
    const [ultima_d, setUltima_d] = useState([])
    const [hoy_s, setHoy_s] = useState({})
    const [hoy_d, setHoy_d] = useState({})
    
    useEffect (() => {
        function traer_detallec(que,donde) {
            fetch(props.back + 'admin/deta_cuota/id/' + que, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + props.token,
                    'Content-Type': 'application/json' 
                }
            })
            .then (response => response.json())
            .then (json => donde(json))
        }
    traer_detallec(props.socio.ultima_s, setUltima_s)
    traer_detallec(props.socio.ultima_d, setUltima_d)
},[]) 

    useEffect (() => {
        function traer_ultima(que, donde) {
            fetch(props.back + 'admin/ultima_cuota/tipo/' + que, {
                method: 'GET',
                headers: {
                  'Authorization': 'Bearer ' + props.token,
                  'Content-Type': 'application/json' 
                }
            })
            .then (response => response.json())
            .then (json => donde(json))
        }
        
    traer_ultima('socio', setHoy_s)
    traer_ultima('actividad', setHoy_d) 
},[]) 

function grupo_familiar () { 
    return  props.familia.map( e => {
        return (<label className="px-10">{e.apellido + ' ' + e.nombre}</label>)
    })
}

return (<>
    <div className="flex flex-row items-center justify-between w-full px-2 mb-3">
        <div className="text-center text-2xl w-1/3">
            {props.socio.apellido +' ' + props.socio.nombre}
        </div>
        {props.socio.saldo_ant ? 
            <div className="text-center text-2xl w-1/3">
                Saldo Anterior $ {props.socio.saldo_ant}
            </div>
        : ''}
        <div className="flex flex-col justify-center w-1/3">
            {props.familia.length ? 
                grupo_familiar()
            : ''
            }
        </div>
    </div>
<div className="grid grid-cols-2">
        <EstadoCuentas socio={props.socio} ultima={ultima_s}  cual='s'/> 
        <EstadoCuentas socio={props.socio} ultima={ultima_d}  cual='d'/> 
        <Vencidas socio={props.socio} importes={props.importes} vencida_s={props.vencida_s} setVencida_s={props.setVencida_s} valor_s={props.valor_s}  />
        <VencidasSoc socio={props.socio}  actividades={props.actividades} vencida_d={props.vencida_d} setVencida_d={props.setVencida_d} valor_d={props.valor_d} />
     </div>
</>)
            
}