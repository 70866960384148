import {useEffect} from 'react'
export default function Actividades(props) {
   useEffect (() => {
        const l = props.socio.actividad.map (a => {
            return a.actividadId
        })    
        props.setSocio({...props.socio, elegido: l})
   },[])

   function generar_lista ()  {
        const l = props.socio.elegido.map (a => {
            const nombre = props.actividades.filter(e => e.id === a)
            if (nombre.length) {
                return (<>
                    <div className="flex flex-row justify-between px-2">
                        <li>{nombre[0].nombre}</li>
                        <label 
                            className="text-lg text-red-600"
                            onClick={() => borrar_actividad(a)}
                            >X
                        </label>
                    </div>
                </>)
            }
            else return ''
        })
        return l
    }

    function agregar_actividad(cual) {
        if (! props.socio.elegido.filter(a => a === cual).length)  {
            props.setSocio({...props.socio, elegido: props.socio.elegido.concat(cual)})
        }
    }

    function borrar_actividad(cual) {
        const t = props.socio.elegido.filter(a => a !== cual)
        props.setSocio({...props.socio, elegido: t})
    }

    return (<>
    <label className="px-2 text-lg text-center w-full font-semibold pl-2 mb-2">Actividades</label>
        <div className="grid grid-cols-2 gap-2 h-[10rem] mb-2">      
        <div className="cursor-pointer overflow-y-auto ">
            <ul className="px-2 mb-2">
            {props.actividades.map(a => {
                return <li key={a.id} onClick={() => agregar_actividad(a.id)}>{a.nombre}</li>
            })}    
            </ul>    
        </div>
        {props.socio.hasOwnProperty('elegido') && props.socio.elegido.length ?
        <div className="px-2">
            <ul>
            {generar_lista()}
            </ul>
            </div>
        
        : '' }
        </div>
    </>)
}