import { useState } from 'react'

export default function BuscarSocio(props) {
    const [apellido, setApellido] = useState('')
    const [nombre, setNombre] = useState('')
    const [dni, setDni] = useState('')

    function documento (e) {
        if (( /^[0-9]+$/.test(e) && e.length<9) || e=== '')
            setDni(e)
    }

   
    return (<>
        <div className="grid grid-cols-3 tex-lg justify-items-center py-1 w-full">
            <div>
                Apellido
                <input 
                    className="w-24 p-1 ml-5"
                    type="text"
                    value={apellido}
                    onChange={e => setApellido(e.target.value)}    
                    key="1234"               
                />
            </div>
            <div>
                Nombre
                <input 
                    className="w-24 p-1 ml-5"
                    type="text"
                    value={nombre}
                    onChange={e => setNombre(e.target.value)}                 
                    key="12345"
                />
            </div>
            <div>
                Documento
                <input 
                    className="w-24 p-1 ml-5"
                    type="text"
                    value={dni}
                    key="123456"
                    onChange={e => documento(e.target.value)}
                />
            </div>
        </div>
        <ul className="overflow-y-auto cursor-pointer h-[33rem]">
        {props.socios.map(a => {
            return (<>
            { (a.nombre.toLowerCase().includes((nombre).toLowerCase()) && a.apellido.toLowerCase().includes((apellido).toLowerCase()) && a.documento.includes((dni))) ?
                <li 
                    key={a.id} 
                    className="grid grid-cols-3 px-2 text-sm"
                    onClick={() => props.setSocio(a)}
                 >
                <div>{a.apellido}</div>
                <div>{a.nombre}</div>
                <div>{a.documento}</div>
                 </li>
                 : '' }
                 </>)
        })}
        </ul>
    </>)
}