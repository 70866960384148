import { useState } from 'react'
import SociosDatos from './socios_datos.js'
import Actividades from './actividades.js'
import Responsable from './responsable.js'
import BuscarSocio from './buscar_socio.js'
import ControlesSocio from './controles_socio.js'
import Bonificaciones from './bonificaciones.js'
import Grupofamiliar from './grupo_familiar.js'
import Buscarsingrupo from './buscar_socio_sin_grupo.js'

export default function Socios(props) {
    const [file, setFile] = useState(null)
    const [editarG, setEditarG ] = useState(false)
      
    return (<>
    <div className="w-full">
        {props.socio.id ? (<>
        <div className="flex flex-row w-full h-[33rem]">
            <div className="w-3/5 my-2 border-r-2 border-gray-600"> 
            {!editarG ?
                <SociosDatos back={props.back} token={props.token} socio={props.socio} setSocio={props.setSocio}  file={file} setFile={setFile}/>
            :
                <Buscarsingrupo back={props.back} token={props.token} socio={props.socio} setSocio={props.setSocio} setEditarG={setEditarG} socios={props.socios}/>
            }
            </div>
            <div className="flex flex-col w-2/5 overflow-y-auto">
                <div className="my-2 border-b-2 border-gray-600">
                    <Actividades socio={props.socio} setSocio={props.setSocio} actividades={props.actividades} />
                </div>
                <div className="my-2 border-b-2 border-gray-600">
                    <Bonificaciones back={props.back} socio={props.socio} setSocio={props.setSocio} token={props.token}/>
                </div>
                <div className="my-2 border-b-2 border-gray-600">
                    <Responsable back={props.back} token={props.token} socio={props.socio} setSocio={props.setSocio}/>
                </div>
                <div className="my-2 border-b-2 border-gray-600">
                    <Grupofamiliar back={props.back} socio={props.socio} setSocio={props.setSocio} token={props.token} setEditarG={setEditarG} socios={props.socios}/>
                </div>
            </div>
        </div>
        {props.socio.id ?
            <ControlesSocio token={props.token} back={props.back} socio={props.socio} setSocio={props.setSocio} recargar={props.recargar} setRecargar={props.setRecargar}  file={file} setFile={setFile}/>
        : '' }
        </>)
        : 
        <div className="p-2">
            <BuscarSocio setSocio={props.setSocio} setRecargar={props.setRecargar} recargar={props.recargar} socios={props.socios}/>
        </div>
        }
    </div>
        
    </>)
}