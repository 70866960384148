import {useState} from 'react'
import dayjs from 'dayjs'
import Fecha from './fecha.js'
import CalcularSocio from './calcular_socio.js'

export default function Socios(props) {
    const [alta, setAlta] = useState(0)
    const formato_text = 'w-64 self-justify-end px-2'
    const data = [
        {cartel: 'apellido', dato: 'apellido'},
        {cartel: 'nombre', dato: 'nombre'},
        {cartel: 'documento', dato: 'documento'},
        {cartel: 'E - Mail', dato: 'email'},
        {cartel: 'teléfono', dato: 'telefono'},
        {cartel: 'dirección', dato: 'direccion'},
        {cartel: 'ciudad', dato: 'ciudad'}
    ]
    function cambiar_Fecha_nac (e) {
        props.setSocio ({...props.socio, nacimiento : dayjs(e).format('YYYY-MM-DD')})
    }

    function cambiar_alta (e) {
        props.setSocio ({...props.socio, alta_sistema : dayjs(e).format('YYYY-MM-DD')})
    }

    return (<>
        <div className="flex flex-row items-start">
            <div className="w-2/5 p-2 flex flex-col justify-center" key="1234">
                <CalcularSocio socio={props.socio} setSocio={props.setSocio} file={props.file} setFile={props.setFile} />
            </div>
            <div className="w-3/5 p-2 flex flex-col" key="12345">
                <div className="flex flex-row pb-2 justify-between">
                    {! props.socio.socio ?
                        <label className="text-red-600">Socio</label>
                    :
                        <label>Socio</label>    
                    }       
                    <input 
                        className={formato_text}
                        type="text"
                        value={props.socio.socio}
                        onChange={e => props.setSocio({...props.socio, socio: e.target.value})}
                    />
                </div>
                {data.map (a => {
                    return (<>
                    <div className="flex flex-row py-2 justify-between" key={a.cartel}>
                    <label className="capitalize">{a.cartel}</label>
                    <input 
                        className={formato_text}
                        type="text"
                        value={props.socio[a.dato]}
                        onChange={e => props.setSocio({...props.socio, [a.dato]: e.target.value})}
                    />
                </div>
                </>)
                })
            }
                <div className="flex flex-row py-2 justify-between">
                    <label>Género</label>
                    <div className="w-64 flex flex-row justify-between">
                        <div className="flex flex-row w-32">
                           <input 
                                type="radio"
                                value='F'
                                onChange={e => props.setSocio({...props.socio, sexo: e.target.value})}
                                checked={props.socio.sexo === "F"}
                            />
                            <label className="pl-5">Mujer</label>
                        </div>
                        <div className="flex flex-row w-32">
                            <input 
                                type="radio"
                                value='M'
                                onChange={e => props.setSocio({...props.socio, sexo: e.target.value})}
                                checked={props.socio.sexo === "M"}
                            />
                            <label className="pl-5">Varón</label>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row py-2 justify-between items-center">
                    <label>Nacimiento</label>
                    <Fecha fecha={props.socio.nacimiento.substring(0,10)} setFecha={cambiar_Fecha_nac}/>
                </div>
                <div className="flex flex-row py-2 justify-between items-center">
                   {! alta ?
                        <label className="text-red-600">Alta</label>
                   : 
                        <label>Alta</label> }
                    <Fecha fecha={props.socio.alta_sistema.substring(0,10)} setFecha={cambiar_alta}/>
                </div>
                <div className="flex flex-row justify-between">
                    <div className="w-20 flex flex-row justify-between pr-5">
                        <input 
                            type="checkbox" 
                            checked={props.socio.baja}
                            onChange={(e => props.setSocio({...props.socio, baja: e.target.checked}))}
                        />
                        <label className="ml-5">Baja</label>
                    </div>
                    <div className="flex flex-row justify-between pr-5">
                        <label>Saldo Anterior</label>
                        <input 
                            className="w-20 text-right ml-5 px-2"
                            type="text"
                            value={props.socio.saldo_ant}
                            onChange={e => props.setSocio({...props.socio, saldo_ant: e.target.value})} />
                    </div>
                </div>
            </div>
            </div>
            </>)             
       }
