import Menu from './menu.js'
import {useState} from 'react'
import LisCateg from './listado_categoria.js'
import LisCaja from './listado_recibo.js'


export default function Listados (props) {
    const [mostrar, setMostrar] = useState('Socio')

    return (<>
        <Menu mostrar={mostrar} setMostrar={setMostrar}/>
        {mostrar==='Socio' ? <LisCateg socios={props.socios} actividades={props.actividades}/> : ''}
        {mostrar==='Caja' ? <LisCaja back={props.back} token={props.token} socios={props.socios} actividades={props.actividades}/> : ''}
    </>)
}