import { useState } from 'react'
export default function BuscarSocio(props) {
   
    function documento (e) {
        if (( /^[0-9]+$/.test(e) && e.length<9) || e=== '')
            props.setDni(e)
    }

    return (<>
        <div className="grid grid-cols-3 tex-lg justify-items-center py-1">
            <div>
                Apellido
                <input 
                    className="w-20 p-1 ml-5"
                    type="text"
                    value={props.apellido}
                    onChange={e => props.setApellido(e.target.value)}
                />
            </div>
            <div>
                Nombre
                <input 
                    className="w-20 p-1 ml-5"
                    type="text"
                    value={props.nombre}
                    onChange={e => props.setNombre(e.target.value)}
                />
            </div>
            <div>
                Documento
                <input 
                    className="w-20 p-1 ml-5"
                    type="text"
                    value={props.dni}
                    onChange={e => documento(e.target.value)}
                />
            </div>
        </div>
        <ul className="overflow-y-auto cursor-pointer h-[13rem]">
        {props.socios.map(a => {
           if (a.nombre_ref.toLowerCase().includes((props.nombre).toLowerCase()) && a.apellido_ref.toLowerCase().includes((props.apellido).toLowerCase()) && a.documento_ref.includes((props.dni))) 
                return  (<>
                <li 
                    key={a.id} 
                    className="grid grid-cols-3 px-2 text-sm"
                    onClick={() => props.setSocio(a)}
                 >
                <div>{a.apellido_ref}</div>
                <div>{a.nombre_ref}</div>
                <div>{a.documento_ref}</div>
                 </li>
                 </>)
        })
    }
    </ul>
    </>)
}