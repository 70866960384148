import DatePicker, { registerLocale} from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import es from "date-fns/locale/es";
registerLocale("es", es);

export default function Recibos (props) {
    return (<>
    <div className="flex flex-row justify-start mx-10">
          <label className="text-lg mr-5">{props.c}</label>
          <DatePicker 
            className="text-black text-lg outline-0 text-right w-32 px-2"
            dateFormat="YYYY / MM / dd"
            locale="es"
            selected={props.fecha} 
            onChange={(e) => props.setFecha(e)}
            filterDate={props.pasado}  
          />
        </div>
    </>)
}