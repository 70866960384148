import Modal from 'react-modal'
import { useState } from 'react'


export default function Login (props) {
  const [usuario, setUsuario] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(false)

  
  function login() {
    fetch(props.back + 'login', {
      body: JSON.stringify({
        usuario: usuario,
        password: password
      }),
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    })
    .then (response => response.json())
    .then (a => {
      if (a.rol=== undefined) {
          props.setToken(a)
          var d = JSON.parse(window.atob(a.split('.')[1]))
          props.setDatos_u(d)
          props.setIsOpen(false)
          setError(false)
      } else {
          setError(true)
      }
      setUsuario('')
      setPassword('')
    })
    .catch((Error) => {
      console.log(Error, error)
    })
  }

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      }
      const appElement = document.getElementById(props.esconder);
      Modal.setAppElement(appElement);

    return (<>
        <Modal
        isOpen={props.isOpen}
        onRequestClose={() => props.setIsOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="w-full flex flex-col bg-gray-700">
            <div className="flex flex-row justify-between items-center px-5">
              <label className="text-white text-lg">Ingreso Administración</label>
              <button onClick={(e) => props.setIsOpen(false)} className="text-lg p-2 text-red-400 ml-10">X</button>
            </div>
            {error ? 
              <div className="flex flex-row justify-center px-5 py-2">
                <label className="mr-5 text-lg text-red-400">¡Revise los datos!</label>
              </div>
              :
              ''
            } 
            <div className="flex flex-row justify-between px-5 py-2">
              <label className="mr-5 text-white text-lg">Usuario</label>
              <input 
                className="text-white w-32 bg-gray-600 border-b-2 border-gray-200 focus:border-red-600 outline-0"
                value={usuario}
                onChange={(e) => setUsuario(e.target.value)}
                />
            </div>
            <div className="flex flex-row justify-between px-5 py-2">
                <label className="mr-5 text-white text-lg">Clave</label>
                <input 
                    type="password"
                    className="text-white w-32 bg-gray-600 border-b-2 border-gray-200 focus:border-red-600 outline-0"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            <div className="flex flex-row justify-center p-2">
                <button 
                  className="p-2 rounded-lg bg-gray-600 border-b-2 border-black text-white text-lg hover:bg-gray-200 hover:text-gray-800"
                  onClick={() => login()}>
                    Ingresar
                </button>

            </div>
      </div>
      </Modal>
    </>)
}