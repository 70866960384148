import { useState } from 'react'

export default function BuscarSingrupo(props) {
    const [apellido, setApellido] = useState('')
    const [nombre, setNombre] = useState('')
    const [dni, setDni] = useState('')
    const prohibidos = props.socio.grupo_fam_lis.split(',')

    function documento (e) {
        if (( /^[0-9]+$/.test(e) && e.length<9) || e=== '')
            setDni(e)
    }

    function agregar(id) {
        if (props.socio.id===id) return
        if (props.socio.grupo_fam_lis==='') 
            props.setSocio({...props.socio, grupo_fam_lis: id.toString()})
        else
            props.setSocio({...props.socio, grupo_fam_lis: props.socio.grupo_fam_lis + ',' + id.toString()})
        props.setEditarG(false)
    }

    return (<>
        <div className="grid grid-cols-3 tex-lg justify-items-center py-1 w-full">
            <div>
                Apellido
                <input 
                    className="w-24 p-1 ml-5"
                    type="text"
                    value={apellido}
                    onChange={e => setApellido(e.target.value)}
                />
            </div>
            <div>
                Nombre
                <input 
                    className="w-24 p-1 ml-5"
                    type="text"
                    value={nombre}
                    onChange={e => setNombre(e.target.value)}
                />
            </div>
            <div>
                Documento
                <input 
                    className="w-24 p-1 ml-5"
                    type="text"
                    value={dni}
                    onChange={e => documento(e.target.value)}
                />
            </div>
        </div>
        <ul className="overflow-y-auto cursor-pointer h-[29rem]">
        {props.socios.map(a => {
            if (a.grupo_fam_cab===a.id) 
                return           
            if (a.grupo_fam_lis!=='') 
                return
            const hay = prohibidos.filter(t => parseInt(t)===a.id)
            if (hay.length)
                return
            
           if (a.nombre.toLowerCase().includes((nombre).toLowerCase()) && a.apellido.toLowerCase().includes((apellido).toLowerCase()) && a.documento.includes((dni))) 
                return  (<>
                <li 
                    key={a.id} 
                    className="grid grid-cols-3 px-2 text-sm"
                    onClick={e => agregar(a.id)}
                 >
                <div>{a.apellido}</div>
                <div>{a.nombre}</div>
                <div>{a.documento}</div>
                 </li>
                 </>)
        })}
        </ul>
    </>)
}