export default function Menu(props) {
    const color_sele = 'text-green-700 text-lg font-semibold'
    const color = 'text-black text-lg font-semibold'
    return (<>
    <div className="flex justify-center border-b-2 border-gray-600">
    <div className="w-1/2 flex flex-row p-2 justify-evenly">
        <label
            onClick={e => props.setMostrar('Socio')}
            className={props.mostrar==='Socio' ? color_sele : color}>
            Socios
        </label>
        <label
            onClick={e => props.setMostrar('Caja')}
            className={props.mostrar==='Caja' ? color_sele : color}>
            Caja
        </label>
    </div>
    </div>
    </>)
}