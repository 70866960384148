
export default function GrupoFamiliar(props) {
    return (<>
       <div className="flex flex-col px-2">
        <label className="text-lg font-semibold">Bonificación (%)</label>
            <div className="flex flex-row w-full py-2 justify-between">
                <label className=""> Cuota Socio</label>
                <input
                    type="text"
                    className="text-right px-2 w-24"
                    value={props.socio.bonifi_s} 
                    onChange={(e => props.setSocio({...props.socio, bonifi_s : e.target.value}))}
                />
            </div>
            <div className="flex flex-row w-full py-2 justify-between">
                <label className="">Cuota Deportiva</label>
                <input
                    type="text"
                    className="text-right px-2 w-24"
                    value={props.socio.bonifi_a} 
                    onChange={(e => props.setSocio({...props.socio, bonifi_a : e.target.value}))}
                />
                
            </div>
    </div>
    </>)
}