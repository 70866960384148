export default function Vencidas (props) {
    
    function elegir (a, hacer) {
        const v = props.vencida_d.map(z => {
            if (hacer && z.id <= a ) 
                z.s = true
            if (!hacer && z.id >= a)
                z.s = false
            return z
        })
        props.setVencida_d(v)
    }

    const formato =  "px-2 flex flex-col border-r-2 border-gray-600 w-full"

    return (<>

        <div className={formato}>
            <label className="text-lg text-center font-semibold mt-5">Cuotas Vencidas</label>
            <ul>
                {props.vencida_d.map(a => {
                    return (<>
                        <li key={a.id}>
                        
                        <div className="flex flex-row justify-between">
                            <div className="flex flex-row justify-between px-2 w-1/2">
                                <input
                                    type="checkbox"
                                    checked={a.s}
                                    onChange={e => elegir (a.id, !a.s)}
                                />
                                <label>{a.descrip}</label>
                            </div>
                            {a.s ?
                                <div className="flex flex-col w-1/2">
                                    {props.valor_d.map(e => {
                                        return e.map(z => {
                                            return (<>
                                                <div key={z.id} className="text-green-700 px-2 flex flex-row justify-between text-lg font-semibold"> 
                                                    <label>{z.act}</label>
                                                    <label>{z.valor}</label>
                                                </div>
                                            </>)
                                        })
                                    })
                                }
                                </div>
                            :
                            ''
                            }
                        </div>
                    </li>
                    </>)
                })
            }
            </ul>
        </div>
    </>)
}