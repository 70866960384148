
import BuscarSocio from './buscar_socio_ing.js'
import MostrarIngreso from './mostrar_ingreso.js'
import BuscarAutorizado from './buscar_autorizado_ing.js'
import {useState} from 'react'
export default function Ingreso(props) {
    const [apellido, setApellido] = useState('')
    const [nombre, setNombre] = useState('')
    const [dni, setDni] = useState('')
    const [apellidor, setApellidor] = useState('')
    const [nombrer, setNombrer] = useState('')
    const [dnir, setDnir] = useState('')
    
    function limpiar() {
        setApellido('')
        setNombre('')
        setDni('')
        setApellidor('')
        setNombrer('')
        setDnir('')
    }

    return (<>
    <div className="flex flex-col w-full">
        <div className="flex flex-row max-h-1/3">
            <div className="max-h-1/3 border-r-2 border-black px-3">
                <p className="text-center text-lg">Socio</p>
                <BuscarSocio setSocio={props.setSocio} setRecargar={props.setRecargar} recargar={props.recargar} actividades={props.actividades} socios={props.socios} apellido={apellido} setApellido={setApellido} nombre={nombre} setNombre={setNombre} dni={dni} setDni={setDni}/>
            </div>
            <div className="max-h-1/3 px-3">
                <p className="text-center text-lg">Autorizado</p>
                <BuscarAutorizado setSocio={props.setSocio} setRecargar={props.setRecargar} recargar={props.recargar} actividades={props.actividades} socios={props.socios} apellido={apellidor} setApellido={setApellidor} nombre={nombrer} setNombre={setNombrer} dni={dnir} setDni={setDnir}/>
            </div>
        </div>
        <div >
        {props.socio.id ?
            <MostrarIngreso back={props.back} token={props.token} socio={props.socio} setSocio={props.setSocio} actividades={props.actividades} apellido={apellidor} limpiar={limpiar} />
        :
        ''
        }
        </div>
    </div>
    </>)
}