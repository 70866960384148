import BotonLogin from './boton_login'
import Quien from './quien'


export default function Barra(props) {
    return (<>
        <div className="w-full bg-gray-800 flex items-center justify-between">
            <div className="p-2">
                <img src="logo.png" alt="" width="50"/>
            </div>
            <div className="text-gray-200 md:text-3xl">Club Social y Deportivo Río Negro</div>
            <div className="bg-gray-800 p-2 flex flex-row justify-between">
                {props.setIsOpen!== undefined ? (<>
                    <div className="p-2">
                       <BotonLogin setIsOpen={props.setIsOpen} />
                    </div>
                    </>)
                :
                    <Quien datos_u={props.datos_u}/>
                }
            </div>
        </div>
    </>)
}